window.addEventListener('DOMContentLoaded', () => {
  const currentUrl = new URL(window.location.href); // 現在のURLを取得
  const seoGroupParam = currentUrl.searchParams.get('seo_group'); // URLからseo_groupパラメータを取得

  // リファラーが存在し、異なるドメインの場合にセッションストレージを更新
  if (document.referrer) {
    const referrerUrl = new URL(document.referrer);
    const referrerUrlHostname = referrerUrl.hostname.toLowerCase();
    if (referrerUrlHostname !== currentUrl.hostname.toLowerCase()) {
      sessionStorage.setItem('seo_group', referrerUrlHostname);
    }  // 同一ドメインの場合は何もしない
  } else if (seoGroupParam) {
    // リファラーがない場合はURLパラメータからseo_groupをセッションストレージに保存
    sessionStorage.setItem('seo_group', seoGroupParam);
  }
});

// 'turbolinks:load'でページ遷移ごとにJSが発火
window.addEventListener('turbolinks:load', () => {
  if (sessionStorage.getItem('seo_group')) {
    document.querySelectorAll('a').forEach((link) => {
      const href = link.getAttribute('href');
      if (!href) return;
      const targetPath = '/classic_entries/new';
      if (href.includes(targetPath)) {
        const newLink = new URL(href);
        newLink.searchParams.set('seo_group', sessionStorage.getItem('seo_group'));
        link.setAttribute('href', newLink.toString());
        link.addEventListener('click', () => {
          sessionStorage.removeItem('seo_group'); // リンククリック後にセッションストレージから情報を削除
        });
      }
    });
  }
});
