window.addEventListener('turbolinks:load', function () {
  const target = document.querySelector('.js-fixed-tab-trigger')
  if (target == null) return

  const options = {
    root: null,
    rootMargin: `10% 0px -${window.innerHeight}px 0px`,
  }

  const observerTab = new IntersectionObserver((entries) => {
    const tab = document.getElementById('js-fixed-tab')
    const tabContents = document.querySelectorAll('.tab_content')
    for (const e of entries) {
      if (e.isIntersecting) {
        tab.classList.add('is-fixed')
        tabContents.forEach((elm) => elm.classList.add('fixed-tab-padding'))
      } else {
        tab.classList.remove('is-fixed')
        tabContents.forEach((elm) => elm.classList.remove('fixed-tab-padding'))
      }
    }
  }, options)

  observerTab.observe(target)
})
