window.addEventListener('turbolinks:load', () => {
  const defaultDispCnt = 3 // 初期表示件数
  const addDispCnt = 6

  let currentDispCnt = 0 // 現在の表示件数
  const commentList = $('.js-comments-item')
  const maxDispCnt = commentList.length
  // 一覧の初期表示
  $(commentList).each(function (i, elem) {
    // 初期表示件数のみ表示
    if (i < defaultDispCnt) {
      $(this).removeClass('u-d-none')
      currentDispCnt++
    }
    if (currentDispCnt < defaultDispCnt) {
      $('.js-hide_display_button').hide()
    }
  })

  $('.js-add_display_button').on('click', function () {
    const newCount = currentDispCnt + addDispCnt // 新しく表示する件数
    // 新しく表示する件数のみ表示
    $(commentList).each(function (i, elem) {
      if (currentDispCnt <= i && i < newCount) {
        $(this).removeClass('u-d-none')
        currentDispCnt++
      }
    })
    $('.js-hide_display_button').show()

    if (maxDispCnt === currentDispCnt) {
      $('.js-add_display_button').hide()
    };
  })

  $('.js-hide_display_button').on('click', function () {
    $(commentList).each(function (i, elem) {
      if (defaultDispCnt <= i && i < currentDispCnt) {
        $(this).addClass('u-d-none')
      }
    })
    currentDispCnt = defaultDispCnt
    $('.js-hide_display_button').hide()
    $('.js-add_display_button').show()
    $('#js-company-comments')[0].scrollIntoView(true)
  })

  // 初期の段階でタグが2未満の場合は「さらに表示」を消す
  if (maxDispCnt === currentDispCnt) {
    $('.js-add_display_button').hide()
  }
})
