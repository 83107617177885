window.addEventListener("turbolinks:load", function () {
  const globalNavSp = document.getElementById("js-global-nav-sp")

  if (!globalNavSp) return

  function slideToggleFunc() {
    $(".global-nav-sp .global-nav-content-has-child").on("click", function () {
      $(this).find(".global-nav-content__child").slideToggle()
      $(this).toggleClass("open")
    })
  }

  slideToggleFunc()

  // sp時背面スクロールを止める
  const slideNavSpCheckBox = document.getElementById("slide-sp-nav")

  slideNavSpCheckBox.addEventListener("change", function () {
    if (this.checked) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
  })
})
