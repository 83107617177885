import Hls from 'hls.js'

function loadHlsVideo(video) {
  const hlsVideoSrc = video.dataset.hlsSrc
  const fallbackVideoSrc = video.dataset.fallbackSrc

  // hls.jsがサポートしているブラウザの場合。
  if (Hls.isSupported()) {
    const hls = new Hls()

    hls.loadSource(hlsVideoSrc)
    hls.attachMedia(video)

    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play()
    })
  // HLSをサポートしているブラウザの場合はsrc属性に直接m3u8ファイルを指定すればよい。
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = hlsVideoSrc

    video.addEventListener('canplay', () => {
      video.play()
    })
  // どうしようもないときは、フォールバック用の動画が指定されているときはそれを再生する。
  } else if (fallbackVideoSrc) {
    video.src = fallbackVideoSrc

    video.addEventListener('canplay', () => {
      video.play()
    })
  }
}

window.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('video.js-hls-video').forEach((video) => loadHlsVideo(video))
})
