window.addEventListener('turbolinks:load', function () {
  let hash = "";
  const TYPES = {
    "company": "#area-tab-company",
    "price": "#area-tab-price",
    "subsidy": "#area-tab-subsidy",
  }
  document.querySelectorAll('[data-city-select-type]').forEach((target) => {
    const type = target.dataset.citySelectType;
    target.addEventListener('click', () => {
      hash = TYPES[type];
    })
  });
  document.querySelectorAll('[data-city-select]').forEach((target) => {
    const button = target.querySelector('[data-city-select-button]');
    const radios = target.querySelectorAll('[name=cities]');

    button.addEventListener('click', () => {
      location.href = target.querySelector('[name=cities]:checked').value + hash
    })

    radios.forEach((radio) => {
      radio.addEventListener('change', () => {
        button.removeAttribute('disabled');
      })
    })
  });

  switch (location.hash) {
    case "#area-tab-company":
      document.getElementById('area-tab-1').click();
      break;
    case "#area-tab-price":
      document.getElementById('area-tab-2').click();
      break;
    case "#area-tab-subsidy":
      document.getElementById('area-tab-3').click();
      break;
    default:
      break;
  }
});
