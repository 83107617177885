import Siema from 'siema'
import 'nodelist-foreach-polyfill'

window.addEventListener('turbolinks:load', function () {
  if (!document.getElementById('js-company-images')) { return }
  const siemas = document.querySelectorAll('.js-slide')

  Siema.prototype.addArrows = function () {
    this.prevArrow = document.createElement('button')
    this.nextArrow = document.createElement('button')
    this.prevArrow.className = 'o-owner-company-images__action is-prev'
    this.nextArrow.className = 'o-owner-company-images__action is-next'
    this.prevArrow.innerHTML = '<i class="fas fa-angle-left"></i>'
    this.nextArrow.innerHTML = '<i class="fas fa-angle-right"></i>'
    this.selector.appendChild(this.prevArrow)
    this.selector.appendChild(this.nextArrow)
    this.prevArrow.addEventListener('click', (e) => this.prev())
    this.nextArrow.addEventListener('click', (e) => this.next())
  }
  siemas.forEach(function () {
    const instance = new Siema({
      selector: '.js-slide',
      perPage: 1
    })
    instance.addArrows()
  })
})
