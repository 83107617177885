window.addEventListener("turbolinks:load", function () {
  const target = document.getElementById("vacant-house-consultations");
  if (!target) return;

  const getParams = window.location.search;

  if (!getParams) return;

  const searchParams = new URLSearchParams(getParams);
  const params = searchParams.toString();

  const applicationButtons = document.querySelectorAll(
    ".vacant-house-consultations-application-button a"
  );

  // パラメータを引き連れて空き家相談申し込みページに遷移
  Array.from(applicationButtons).forEach((button) => {
    // app/views/service/vacant_house_consultations/_cta_contents.html.slim 側で遷移先を設定しているので要注意
    // 意図せず上書きしないように念のためurl文字列をチェック
    const link = "/special/application_for_vacant_house_consultation/";
    if (button.href.indexOf(link) != -1) {
      button.href = link + "?" + params;
    } else return;
  });
});
