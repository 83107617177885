window.addEventListener('turbolinks:load', () => {
  if ($('.js-company-map').data('company_address') !== undefined) {
    const mapLocation = {}
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ address: $('.js-company-map').data('company_address'), region: 'jp' }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        mapLocation.lat = results[0].geometry.location.lat()
        mapLocation.lng = results[0].geometry.location.lng()

        const map = new google.maps.Map(document.getElementById('g_map'), {
          center: mapLocation,
          zoom: 15,
          streetViewControl: false
        })
        // eslint-disable-next-line no-new
        new google.maps.Marker({ position: mapLocation, map: map, draggable: true })
      }
    })
  };
})
