import jQuery from 'jquery'

window.addEventListener('turbolinks:load', () => {
  if (!document.querySelector('.service-evaluations__item')) { return }

  const defaultDispCnt = 5 // 初期表示件数
  const addDispCnt = 5

  let currentDispCnt = 0 // 現在の表示件数
  const reviewList = jQuery('.service-evaluations__item')
  const maxDispCnt = reviewList.length

  // 一覧の初期表示
  jQuery(reviewList).each(function (i, elem) {
    // 初期表示件数のみ表示
    if (i < defaultDispCnt) {
      jQuery(this).removeClass('u-d-none')
      currentDispCnt++
    }
  })

  jQuery('.js-click-to-hide').on('click', function (e) {
    e.preventDefault()
    const newCount = currentDispCnt + addDispCnt // 新しく表示する件数
    // 新しく表示する件数のみ表示
    jQuery(reviewList).each(function (i, elem) {
      if (currentDispCnt <= i && i < newCount) {
        jQuery(this).removeClass('u-d-none')
        currentDispCnt++
      }
    })

    if (maxDispCnt === currentDispCnt) {
      jQuery('.js-click-to-hide').hide()
    };
  })
})
