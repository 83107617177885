window.addEventListener('turbolinks:load', function () {
  const target = document.getElementById('show-to-top-trigger');
  if (target == null) return;

  const toTopButton = document.getElementById('js-roots-to-top');
  if (toTopButton == null) return;

  toTopButton.addEventListener('click', function () {
    target.scrollIntoView({
      behavior: 'smooth',
    });
  });

  const options = {
    root: null,
    rootMargin: `${window.innerHeight / 2}px 0px`,
  };

  const observeToTop = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      toTopButton.classList.remove('is-visible');
    } else {
      toTopButton.classList.add('is-visible');
    }
  }, options);

  observeToTop.observe(target);
});
