document.addEventListener("turbolinks:load", () => {
  const footerGlobalMenuListItem = Array.from(
    document.querySelectorAll(
      ".roots-footer__global-inner__menu-container__list-inner__title"
    )
  );

  footerGlobalMenuListItem.forEach((listItem) => {
    const content = listItem.nextElementSibling;

    listItem.addEventListener("click", () => {
      listItem.classList.toggle("is-active");
      content.classList.toggle("is-open");
      content.classList.toggle(
        "is-close",
        !content.classList.contains("is-open")
      );
    });
  });
});
