window.addEventListener("turbolinks:load", function () {
  var prefectureSelect = $("[data-subsidy-select='prefecture']")
  var citySelect = $("[data-subsidy-select='city']")
  var subsidyContent = $("[data-subsidy-content]")
  var subsidyTitle = $("[data-subsidy-title]")

  // 都道府県
  prefectureSelect.on("change", onChangePrefecture)
  // 市区町村
  citySelect.on("change", onChangeCity)

  if (prefectureSelect.val()) {
    fetchCities(prefectureSelect.val())
  }

  // 都道府県選択
  function onChangePrefecture() {
    var prefecture_id = $(this).val()
    fetchCities(prefecture_id)
  }

  // 市区町村一覧取得
  function fetchCities(prefecture_id) {
    $.ajax("/area/api/cities", {
      type: "get",
      data: { prefecture_id: prefecture_id },
      dataType: "json",
    }).done(function (cities) {
      renderCities(cities)
    })
  }

  // 市区町村一覧描画
  function renderCities(cities) {
    var optionsHtml = ""
    cities.forEach(function (city) {
      optionsHtml += `<option data-subsidy-option value="${city.id}">${city.name}</option>`
    })
    citySelect.find("option[data-subsidy-option]").remove()
    citySelect.find("option").after(optionsHtml)
  }

  function onChangeCity() {
    var prefecture_id = prefectureSelect.val()
    var city_id = $(this).val()
    fetchSubsidy(prefecture_id, city_id)
  }
  // 補助金検索
  function fetchSubsidy(prefecture_id, city_id) {
    $.ajax("/area/api/demolition_subsidy", {
      type: "get",
      data: { prefecture_id: prefecture_id, city_id: city_id },
      dataType: "json",
    }).done(function (data) {
      renderSubsidy(data.html)
    })
  }

  // 補助金情報描画
  function renderSubsidy(html) {
    var cityName = citySelect.find(":selected").text()
    if (html === null || html == "") {
      html = "補助金情報がありません"
    }
    subsidyTitle.text(`${cityName}の解体工事補助金`)
    subsidyContent.html(html)
    subsidyContent.parent().removeClass("d-none")
  }
})
