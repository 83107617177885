import Siema from 'siema'

window.addEventListener('turbolinks:load', () => {
  if (!document.querySelector('.js-prefecture-demolition-campanies')) { return }

  const prefectureDemolitionCampanies = new Siema({
    selector: '.js-prefecture-demolition-campanies',
    perPage: {
      480: 1,
      768: 2,
      1024: 3,
      4000: 4
    }
  })

  const prev = document.querySelector('.js-prefecture-demolition-campanies__prev')
  const next = document.querySelector('.js-prefecture-demolition-campanies__next')

  prev.addEventListener('click', () => prefectureDemolitionCampanies.prev())
  next.addEventListener('click', () => prefectureDemolitionCampanies.next())
})
