window.addEventListener("turbolinks:load", function () {
  const globalNavPc = document.getElementById("js-global-nav-pc")

  if (!globalNavPc) return

  // hoverした時にoverlayを表示する
  const globalNavPcOverlay = document.getElementById("js-global-nav-pc-overlay")

  const hoverOverlayPcItems = Array.from(
    document.querySelectorAll(
      ".global-nav-content__item.global-nav-content-has-child"
    )
  )

  hoverOverlayPcItems.forEach((hoverOverlayPcItem) => {
    const childItem = hoverOverlayPcItem.firstElementChild.nextElementSibling
    hoverOverlayPcItem.addEventListener("mouseover", function () {
      globalNavPcOverlay.style.display = "block"
    })

    hoverOverlayPcItem.addEventListener("mouseleave", function () {
      globalNavPcOverlay.style.display = ""
    })

    childItem.addEventListener("click", function () {
      globalNavPcOverlay.style.display = ""
    })
  })
})
