window.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('[data-toggle]').forEach((target) => {
    let isOpen = false;
    const toggleClass = target.dataset.toggleClass;
    const clickable = target.querySelector('[data-toggle-clickable]')

    clickable.addEventListener('click', () => {
      if (isOpen) {
        target.classList.remove(toggleClass)
        isOpen = false
      } else {
        target.classList.add(toggleClass)
        isOpen = true
      }
    });
  });
});
