// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";

import "../src/areaSelector";
import "../src/companyComments";
import "../src/companyDetails";
import "../src/companyMap";
import "../src/loadHls";
import "../src/objectFitImages";
import "../src/prefectureDemolitionCompanies";
import "../src/serviceEvaluationsItem";
import "../src/searchDemolitionSubsidy";
import "../src/toggle";
import "../src/citySelect";
import "../src/getReferrer";
import "../src/fixedTab";
import "../src/toTop";
import "../src/mainVisualSlider";
import "../src/globalNavSp";
import "../src/globalNavPc";
import "../src/footerAccordion";
import "../src/disabledTurbolinks";
import "../src/vacantHouseConsultationsCtaParams";

Rails.start();
Turbolinks.start();
