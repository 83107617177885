import { Splide } from "@splidejs/splide"

window.addEventListener("turbolinks:load", () => {
  const splideMainVisualContainer = document.getElementById(
    "splide-main-visual-container"
  )

  if (splideMainVisualContainer == null) return

  const options = {
    mediaQuery: "min",
    autoWidth: false,
    gap: "0px",
    autoWidth: false,
    autoplay: true,
    interval: 5000,
    speed: 1000,
    pauseOnFocus: false,
    type: "loop",
    focus: "center",
    breakpoints: {
      1120: {
        gap: "8px",
        autoWidth: true,
      },
    },
  }

  const mainVisualSplide = new Splide(splideMainVisualContainer, options)

  mainVisualSplide.mount()
})
